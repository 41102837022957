import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import httpService from "../../services/http.service";
import "./SignUpOTP.css";
import { statementError } from "../../helper/ToastHelper";
import { Link } from "react-router-dom";
import signupLogo from "../../assets/signUpPageHeadingLogo2.svg";
import { toast } from "react-toastify";
import SignUpMain from "../signUpMain/SignUpMain";

const SignUpOTP = () => {
  const navigate = useNavigate();
  const username = useLocation().state?.username ?? "";
  const email = useLocation().state?.email ?? "";
  const INITIAL_SECONDS = 30;
  const [secondsLeft, setSecondsLeft] = useState(INITIAL_SECONDS);
  const formattedSeconds =
    secondsLeft < 10
      ? secondsLeft.toString().padStart(2, "0") //   0:08
      : secondsLeft.toString(); //   0:15
  const [isResendOtpButtonDisabled, setIsResendOtpButtonDisabled] =
    useState(true);
  const [isVerifyOtpButtonDisabled, setIsVerifyOtpButtonDisabled] =
    useState(false);
  const [otp, setOtp] = useState("");

  // Countdown timer
  useEffect(() => {
    let interval;

    if (isResendOtpButtonDisabled) {
      // Start the timer only if the "Resend OTP" button is disabled
      interval = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval); // Stop the interval when seconds reach 0
            setIsResendOtpButtonDisabled(false); // Enable the "Resend OTP" button
            return 0;
          }
        });
      }, 1000);
    }

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [isResendOtpButtonDisabled]); // To restart timer when "Resend OTP" button is clicked

  function handleResendOtp() {
    setSecondsLeft(INITIAL_SECONDS); // Reset the timer to its initial value
    setIsResendOtpButtonDisabled(true); // Disable the "Resend OTP" button
    // getSuccessToast("OTP sent again");
    toast.success("OTP sent again", {
      position: "top-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    httpService.sendSignUpOtp(username).catch(() => {
      statementError("Something went wrong with the OTP service", 5000);
    });
  }

  function handleOtpVerification() {
    setIsVerifyOtpButtonDisabled(true);
    httpService
      .verifySignUpOtp(username, otp)
      .then((data) => {
        // "Account Verification Successful !!" + " Please Login to continue."
        // getSuccessToast(data?.data?.message, 5000);
        toast.success("Account verification successful !!", {
          position: "top-left",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/login-route");
        // navigate("/sign-up", { state: { formState: false }});
      })
      .catch((data) => {
        statementError(data.response.data.message, 5000);
      })
      .finally(() => {
        setIsVerifyOtpButtonDisabled(false);
      });
  }

  return (
    <>
      <SignUpMain>
        <div className="my-auto px-14 md:px-20  lg:px-32 ">
          <div>
            <img src={signupLogo} alt="logo" className="mx-auto" />
          </div>
          <div className="customSignup mt-10 ">
            <div className="xs:px-2 otp-verification-card flex flex-col items-center rounded-lg bg-white p-5 shadow-lg sm:px-4 md:px-5 lg:px-5">
              <h1>Account Verification</h1>
              <h2>Please enter verification code sent to</h2>
              <h3>{email}</h3>
              <InputText
                id="signup-otp"
                keyfilter="pint"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP"
                maxLength={6}
                className="my-3 mt-6"
              />
              <Link to="/change-email" state={useLocation().state}>
                <h4>change email?</h4>
              </Link>
              <div className="mt-3 flex justify-center">
                <button
                  className={`border-primary text-primary otp-verification-card_outlined-button mx-3 rounded-lg border px-3 py-2 ${isResendOtpButtonDisabled
                    ? "cursor-not-allowed opacity-50 border-blue-500"
                    : ""
                    }`}
                  onClick={handleResendOtp}
                  disabled={isResendOtpButtonDisabled}
                >
                  Resend OTP{" "}
                  {secondsLeft > 0 && <span>{`in 0:${formattedSeconds}`}</span>}
                </button>
                <button
                  className={`mx-3 rounded-lg bg-blue-500 px-3 py-2 text-white ${otp.length < 6 || isVerifyOtpButtonDisabled
                    ? "cursor-not-allowed opacity-50"
                    : ""
                    }`}
                  label="Verify OTP"
                  onClick={handleOtpVerification}
                  disabled={otp.length < 6 || isVerifyOtpButtonDisabled}
                >
                  Verify OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </SignUpMain>
    </>
  );
};

export default SignUpOTP;
