import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./SignUp.css";
import { Tooltip } from "primereact/tooltip";
import userIcon from "../../assets/userIcon.svg";
import emailIcon from "../../assets/emailIcon.svg";
import passwordIcon from "../../assets/passwordIcon.svg";
import companyIcon from "../../assets/companyIcon.svg";
import firstname from "../../assets/First name.svg";
import lastname from "../../assets/Last name.svg";
import { toast } from "react-toastify";
import { Password } from "primereact/password";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import httpService from "../../services/http.service";
import { Dropdown } from "primereact/dropdown";
import { decodeParam, logPageTitleForGA } from "../../helper/Helper";
import { statementError } from "../../helper/ToastHelper";
import md5Hex from "md5-hex";
import signupLogo from "../../assets/signUpPageHeadingLogo2.svg";
import signupImage from "../../assets/signUpPageImage.svg";
import upperRing from "../../assets/signUpBackgroundRing.svg";
import bottomRing from "../../assets/signUpBackgroundRings.svg";


const SignUp = (props) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const companySingle = useRef("");
  const [validUsername, setValidUsername] = useState(false);
  const [showvalid, setShowValid] = useState(false);
  // const [strengthCheckValue, setStrengthCheckValue] = useState(false);
  const [showInputBox, setShowInputBox] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [detailsVerified, setDetailsVerified] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [companyDropdown, setCompanyDropdown] = useState("");
  const [validUsernameMessage, setValidUsernameMessage] = useState(null);
  const [focused, setFocused] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiCallsInProgress, setApiCallsInProgress] = useState(false);
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPswd, setShowConfirmPswd] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPswd((prevState) => !prevState);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    strengthCheck(e.target.value);
  };

  const strengthCheck = (password) => {
    const mediumRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
    );
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})",
    );

    if (strongRegex.test(password)) {
      setFeedback("Strong");
    } else if (mediumRegex.test(password)) {
      setFeedback("Medium");
    } else {
      setFeedback(null);
    }
  };

  // const isPasswordValid = strengthCheck(password);
  // const getPasswordTemplate = (props) => {
  //   return (
  //     <div>
  //       {feedback && <span className={`password-strength ${feedback}`}></span>}{" "}
  //     </div>
  //   );
  // };

  useEffect(() => {
    let title = "GemEcosystem | Signup";
    document.title = title;
    logPageTitleForGA(title);
    // setShowForm(true);
    let urlParams = JSON.parse(decodeParam(searchParams.get("metaData")));
    setEmail(urlParams?.email);
    setFirstName(urlParams?.given_name);
    setLastName(urlParams?.family_name);
  }, []);
  useEffect(() => {
    const pattern = /[#%&\\]/;
    if (username.length > 0) {
      if (pattern.test(username)) {
        setValidUsername(true);
        setValidUsernameMessage("Invalid username entered");
        setShowValid(true);
        return;
      }
      var timeout = setTimeout(() => {
        httpService.validateUserName(username).then((data) => {
          setShowValid(true);
          if (data["isPresent"]) {
            setValidUsername(true);
            setValidUsernameMessage(data?.["Validity"]);
          } else {
            setValidUsername(false);
            setValidUsernameMessage(data?.["Validity"]);
          }
        });
      }, 500);
    } else {
      setShowValid(false);
    }
    // return () => {
    //   clearTimeout(timeout);
    // };
  }, [username]);

  const usernameHeader = (
    <>
      <span>
        <Tooltip
          target=".custom-target-icon-username"
          className="signupTooltip"
        >
          <>
            <p className="">Valid Username Suggestions</p>
            <p className="mt-1">
              Mandatory
              <ul className="mt-0" style={{ lineHeight: "1.5" }}>
                <li>Alphabetical characters </li>
              </ul>
            </p>
            <p className="mt-1">
              Optional
              <ul className="mt-0" style={{ lineHeight: "1.5" }}>
                <li>Any Numerical value</li>
                <li>Special characters (dot,hyphen,underscore)</li>
              </ul>
            </p>
          </>
        </Tooltip>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="infoicon fa-sm custom-target-icon-username ms-2"
          // data-pr-tooltip={footer}
          data-pr-position="right"
          data-pr-at="right+2 top"
        />
      </span>
    </>
  );
  const header = (
    <>
      <span>
        <Tooltip target=".custom-target-icon" className="signupTooltip">
          <>
            <p className="">Suggestions</p>
            <ul className="mt-0" style={{ lineHeight: "1.5" }}>
              <li>At least one lowercase</li>
              <li>At least one uppercase</li>
              <li>At least one numeric</li>
              <li>At least one special character</li>
              <li>Minimum 8 characters</li>
            </ul>
          </>
        </Tooltip>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="infoicon fa-sm custom-target-icon ms-2"
          data-pr-position="right"
          data-pr-at="right+2 top"
        />
      </span>
    </>
  );

  const checkCompany = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      firstName?.length === 0 ||
      lastName?.length === 0 ||
      username.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0
    ) {
      toast.error("All fields are required !", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!/^[A-Za-z]+$/i.test(firstName?.trim())) {
      toast.error("Invalid firstname", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!/^[A-Za-z]+$/i.test(lastName?.trim())) {
      toast.error("Invalid lastname", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!/^(?=.*[a-zA-Z])[a-zA-Z0-9_.-]*$/i.test(username)) {
      toast.error("Invalid username", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      toast.error("Invalid Email !", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (password !== confirmPassword) {
      toast.error("Password and Confirm Password should be perfect match !", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      if (feedback === null) {
        toast.error("Password too weak ! ", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setApiCallsInProgress(true);
        httpService
          .getCompany(email)
          .then(async (data) => {
            if (typeof (await data["data"]) === "string") {
              companySingle.current = await data["data"];
              setShowInputBox(true);
              registerUser(e, companySingle.current);
            } else {
              setShowDropDown(true);
              setCompanyOptions(data["data"]);
              setDetailsVerified(true);
            }
          })
          .catch((err) => {
            statementError("Soemthing Went Wrong ! ");
          })
          .finally(() => {
            setApiCallsInProgress(false);
          });
      }
    }
  };

  const registerUser = (e, companyName) => {
    e.preventDefault();
    if (companyName.length > 0) {
      setIsLoading(true);
      setApiCallsInProgress(true);
      httpService
        .register(
          firstName?.trim(),
          lastName?.trim(),
          username,
          email,
          md5Hex(password),
          companyName,
        )
        .then((data) => {
          setIsLoading(false);
          setApiCallsInProgress(false);
          toast.success("User Registered.", {
            position: "top-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/account-verification", {
            state: {
              firstName: firstName,
              lastName: lastName,
              username: username,
              email: email,
              hashedPassword: md5Hex(password),
              companyName: companyName,
            },
          });
        })
        .catch((err) => {
          setShowInputBox(false);
          setShowDropDown(false);
          setDetailsVerified(false);
          setEmail("");
          companySingle.current = "";
          setCompanyDropdown("");
          setIsLoading(false);
          setApiCallsInProgress(false);
        });
    } else {
      toast.error("Please fill out company ! ", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <div className="signUp-background-wrapper">
        <div className="upper-ring hidden lg:block md:block">
          <img src={upperRing} alt="logo" />
        </div>
        <div className="bottom-ring hidden lg:block md:block">
          <img src={bottomRing} alt="logo" />
        </div>
        <div className="mx-auto h-screen grid max-w-screen-2xl grid-cols-1 lg:grid-cols-2 md:grid-cols-2 ">
          <div className="px-14 md:px-20 lg:px-32  lg:py-14 py-36 h-screen ">
            <div className="">
              <img src={signupLogo} alt="logo" className="mx-auto" />
            </div>
            <div className="card customSignup flex ">
              <div className="flex-1 px-8 py-8 lg:px-8 lg:py-8">
                <div className="text-center">
                  <span className="titleSignup">Register New User</span>
                  <br />
                  <span className="acc">
                    Create your account and get started.
                  </span>
                </div>
                <form
                  className="formSignup px-6"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="inputfield mt-3">
                    <img src={firstname} />
                    <input
                      type="text"
                      className="focus:shadow-outline appearance-none rounded border px-14 py-2 leading-tight text-gray-700 focus:outline-none"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Firstname"
                      disabled={showInputBox || showDropDown}
                    />
                  </div>
                  <div className="inputfield mt-3">
                    <img src={lastname} />
                    <input
                      type="text"
                      className="focus:shadow-outline appearance-none rounded border px-3 py-2 leading-tight text-gray-700 focus:outline-none"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Lastname"
                      disabled={showInputBox || showDropDown}
                    />
                  </div>
                  <div className="inputfield mt-3">
                    <img src={userIcon} />
                    <input
                      onBlur={() => {
                        setShowValid(false);
                        setFocused(false);
                      }}
                      onFocus={() => {
                        setFocused(true);
                        if (username.length > 0) {
                          setShowValid(true);
                        }
                      }}
                      type="text"
                      className={`focus:shadow-outline appearance-none rounded border px-3 py-2 leading-tight text-gray-700 focus:outline-none ${validUsername && username.length > 0
                        ? "notValidUsername"
                        : ""
                        }`}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      value={username}
                      placeholder="Username"
                      disabled={showInputBox || showDropDown}
                    />
                    <div className="infoUsername me-3">{usernameHeader}</div>

                    {showvalid && focused ? (
                      validUsername ? (
                        <span className="notAvail ms-2">
                          <span className="me-1">{validUsernameMessage}</span>
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </span>
                      ) : (
                        <span className="avail ms-2">
                          <span className="me-1">{validUsernameMessage}</span>
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                      )
                    ) : null}
                  </div>
                  <div className="inputfield mt-3">
                    <img src={emailIcon} />
                    <input
                      type="email"
                      className="focus:shadow-outline appearance-none rounded border px-3 py-2 leading-tight text-gray-700 focus:outline-none"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      disabled={showInputBox || showDropDown}
                    />
                  </div>

                  <div className="inputfield mt-3">
                    <img
                      src={passwordIcon}
                      alt="icon"
                    />
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => handlePasswordChange(e)}
                      placeholder={"Password"}
                      disabled={showInputBox || showDropDown}
                      className="focus:shadow-outline appearance-none rounded border px-3 py-2 leading-tight text-gray-700 focus:outline-none"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="eye-toggle-button"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? "👁️" : "👁️‍🗨️"}
                    </button>
                  </div>
                  <div>
                    {password && (
                      <div className="mt-1">
                        <div className="strength-meter">
                          <div
                            className={`strength-meter-fill strength-${feedback}`}
                          />
                        </div>
                        <p style={{ fontWeight: "bold" }}>
                          Password Strength: {feedback ? feedback : "weak"}
                          {header}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="inputfield mt-3" style={{ position: "relative" }}>
                    <img
                      src={passwordIcon}
                      alt="icon"
                      style={{ position: "absolute", top: "50%", left: "10px", transform: "translateY(-50%)" }}
                    />
                    <input
                      type={showConfirmPswd ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder={"Confirm Password"}
                      disabled={showInputBox || showDropDown}
                      className="focus:shadow-outline appearance-none rounded border px-3 py-2 leading-tight text-gray-700 focus:outline-none"
                    />
                    <button
                      type="button"
                      onClick={toggleConfirmPasswordVisibility}
                      className="eye-toggle-button"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {showConfirmPswd ? "👁️" : "👁️‍🗨️"}
                    </button>
                  </div>

                  {showInputBox && (
                    <div className="inputfield mt-3">
                      <img src={companyIcon} />
                      <input
                        type="text"
                        className="form-control"
                        value={companySingle.current}
                        disabled={true}
                        placeholder="Company Name"
                      />
                    </div>
                  )}

                  {showDropDown ? (
                    <div className="dropdownfield mt-3">
                      <img src={companyIcon} />
                      <Dropdown
                        className="dialog-dropdown"
                        options={companyOptions}
                        value={companyDropdown}
                        onChange={(e) => setCompanyDropdown(e.target.value)}
                        placeholder="Select Company Name"
                        maxSelectedLabels={1}
                        editable
                      />
                    </div>
                  ) : null}
                  {detailsVerified ? (
                    <div className="text-center">
                      <button
                        type="submit"
                        disabled={
                          isLoading || validUsername || apiCallsInProgress
                        }
                        className={`mt-5 rounded-md bg-blue-500 px-3 py-3  text-white ${isLoading || validUsername || apiCallsInProgress
                          ? "cursor-not-allowed opacity-50"
                          : ""
                          }`}
                        onClick={(e) => registerUser(e, companyDropdown)}
                      >
                        Sign Up
                      </button>
                    </div>
                  ) : (
                    <div className="text-center">
                      <button
                        type="submit"
                        className={`mt-5 rounded-md bg-blue-500 px-3 py-3 text-white ${showInputBox ||
                          isLoading ||
                          validUsername ||
                          apiCallsInProgress
                          ? "cursor-not-allowed opacity-50"
                          : ""
                          }`}
                        onClick={(e) => checkCompany(e)}
                        disabled={
                          showInputBox ||
                          isLoading ||
                          validUsername ||
                          apiCallsInProgress
                        }
                      >
                        Sign Up
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="hidden lg:ml-14 lg:my-auto lg:block md:block lg:w-3/4 md:w-3/4">
            <img src={signupImage} alt="logo" className="h-full w-full" />
          </div>
        </div >
      </div >
    </>
  );
};

export default SignUp;
